
import { syncLogout } from '@/modules/user/user'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'
import TheAvatar from '@/pages/setting/components/TheAvatar.vue'
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import TheSettingItem from '@/features/fbLogin/TheSettingItem.vue'

export default defineComponent({
  name: 'Setting',
  components: { Line, Icon, PageWithHeader, TheAvatar },
  setup () {
    return {
      nickName: localGet(keymap.user.nickName),
      account: localGet(keymap.user.accountNum),
      mobile: localGet(keymap.user.mobile),
      islamFlag: Boolean(Number(localGet(keymap.user.islamFlag))) || false,
      logOut: syncLogout,
      avatar: localGet(keymap.user.avatar),
    }
  },
})
