
import Pic from 'common/Pic.vue'
export default {
  name: 'TheAvatar',
  components: { Pic },
  props: {
    avatar: {
      type: String,
    },
  },
}
